

import { Vue, Component } from 'vue-property-decorator'
import { DeviceTypeList, GarbageStationList, DeviceRecovery } from '@/types/devices'

@Component
export default class GarbageStation extends Vue {
  private deviceTypeList: object[] = []
  private tableData: DeviceRecovery[] = []
  private loading = false
  private searchInfo: object = {
    deviceTypeId: '',
    deviceCode: '',
    deviceNumber: ''
  }

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getDeviceTypeList()
    this.getList()
  }

  // 获取设备类型列表
  getDeviceTypeList () {
    this.$axios.get<DeviceTypeList>(this.$apis.devices.getDeviceTypeList).then(res => {
      this.deviceTypeList = res.list
    })
  }

  // 搜索
  onSearch (): void {
    this.page = 1
    this.getList()
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<GarbageStationList>(this.$apis.devices.selectDeviceRecycleByList,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  // 还原
  onReduction (deviceId: string) {
    this.$confirm('确认还原该设备吗？', '提示').then(() => {
      this.$axios.post(this.$apis.devices.recycleDevice, {
        deviceId: deviceId
      }).then(() => {
        this.$message.success('操作成功')
        this.onSearch()
      })
    })
  }
}
