import { render, staticRenderFns } from "./GarbageStation.vue?vue&type=template&id=7cd8bda8&scoped=true"
import script from "./GarbageStation.vue?vue&type=script&lang=ts"
export * from "./GarbageStation.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd8bda8",
  null
  
)

export default component.exports